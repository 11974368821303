<template>
  <MainLayout>
    <template v-slot:navbar-title>
      {{ `${name}` }}
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Воспитанник
      </template>
      <template v-slot:title-flex-right>
        <div></div>
      </template>
      <template>
        <!--    Данные компании    -->
        <template>
          <!--    Данные компании    -->
          <div>
            <FormCardTitle class="data__text">Воспитанник</FormCardTitle>
            <FormInputBlock>
              <!--              <ValidationInputField-->
              <!--                rules="required"-->
              <!--                label="Фамилия"-->
              <!--                v-model="lastName"-->
              <!--                validate-name="Фамилия"-->
              <!--              />-->
              <!--              <ValidationInputField-->
              <!--                rules="required"-->
              <!--                label="Имя"-->
              <!--                v-model="name"-->
              <!--                validate-name="Имя"-->
              <!--              />-->
              <!--              <ValidationInputField-->
              <!--                label="Отчество"-->
              <!--                v-model="middleName"-->
              <!--                validate-name="Отчество"-->
              <!--              />-->
              <ValidationInputField
                rules="required"
                label="Табельный номер"
                v-model="personalNumber"
                validate-name="табельный номер"
              />
              <ValidationInputField
                label="Воспитанник"
                v-model="name"
                validate-name="воспитанник"
                :rules="'min:1|max:40'"
              ></ValidationInputField>

              <ValidationAutocompleteField
                :search-function="childrenGroupSearch"
                rules="required"
                label="Детская группа"
                v-model="ChildrenGroup"
                validate-name="Детская группа"
                :disabled="true"
              />
            </FormInputBlock>
          </div>

          <!--    Данные компании    -->
        </template>
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(editChildren)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import { getChildrenGroupsRequest } from "@/helpers/api/childrenGroup";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "EditChildren",
  components: {
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout
  },
  data() {
    return {
      loading: false,
      childrenGroupSearch: async value => {
        const id = this.$store.getters.getKindergartenId;
        return (
          await getChildrenGroupsRequest({
            query: {
              kindergarten_org: this.kindergartenId || id,
              search: value
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      },
      parents: [] // родители ребенка
    };
  },
  computed: {
    name: {
      get() {
        return this.$store.getters.getEditChildren.name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditChildren", {
          fieldName: "name",
          value: newValue
        });
      }
    },
    personalNumber: {
      get() {
        return this.$store.getters.getEditChildren.number.value;
      },
      set(newValue) {
        this.$store.commit("changeEditChildren", {
          fieldName: "number",
          value: newValue
        });
      }
    },
    comments: {
      get() {
        return this.$store.getters.getEditChildren.comments.value;
      },
      set(newValue) {
        this.$store.commit("changeEditChildren", {
          fieldName: "comments",
          value: newValue
        });
      }
    },
    ChildrenGroup: {
      get() {
        return this.$store.getters.getEditChildren.children_group.value;
      },
      set(newValue) {
        this.$store.commit("changeEditChildren", {
          fieldName: "children_group",
          value: newValue
        });
      }
    }
  },
  created() {
    this.$store
      .dispatch("prepareEditChildren", {
        id: this.$route.params.id
      })
      .then(response => {
        this.parents = response.parents_details;
      });
  },
  methods: {
    editChildren() {
      this.loading = true;
      this.$store
        .dispatch("editChildren", { id: this.$route.params.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.children)
          );
        })
        .finally(() => (this.loading = false));
    },

    deleteParent(id) {
      if (this.parents.length <= 1) {
        this.$store.dispatch(
          "warningNotification",
          "Вы не можете удалить единственного родителя у ребенка, для начала добавьте другого родителя!"
        );
      } else {
        this.parents = this.parents.filter(el => el.id != id);
        this.$store.commit("changeEditChildren", {
          fieldName: "parents",
          value: this.parents.map(el => el.id)
        });
      }
    }
  }
};
</script>

<style scoped></style>
